<template>
  <div class="w-full h-full flex flex-col">
    <h2 v-if="showTitle" class="capitalize mx-auto flex justify-center">
      {{ $t("message.what-is-your-email") }}
    </h2>
    <form @submit.prevent="next()">
      <InputWrapper
        class="mt-4"
        name="email"
        :label="$t('message.your-email-address')"
        type="email"
        :error="hasError('email')"
        :touched="touched('email')"
        :placeholder="$t('message.email-address')"
        :hint="hint('email')"
        v-model="email"
        @input="validateForm()"
        @blur="changed('email')"
      />
      <button type="submit" class="mx-auto btn btn-lg btn-default my-4">
        <span class="mx-auto">
          {{ $t("message.next") }}
        </span>
      </button>
    </form>
    <div class="or font-body2 mx-auto text-center capitalize">
      {{ $t("message.or") }}
    </div>
    <div class="message font-tiny text-center my-2">
      {{ $t("message.you-can-also-sign-in-federated") }}
    </div>
    <button
      class="btn btn-lg btn-clear relative my-2 mx-auto"
      @click="callFederatedSignIn('Facebook')"
    >
      <img
        class="absolute left-4"
        src="@/assets/img/icons/facebook-blue.svg"
        alt="facebook"
      />
      <span>
        {{ $t("message.continue-with", ["Facebook"]) }}
      </span>
    </button>
    <button
      class="btn btn-lg btn-clear my-2 relative mx-auto"
      @click="callFederatedSignIn('Google')"
    >
      <img
        class="absolute left-4"
        src="@/assets/img/icons/google-blue.svg"
        alt="google"
      />
      <span>
        {{ $t("message.continue-with", ["Google"]) }}
      </span>
    </button>
    <router-link
      :to="{ name: 'SignInPage' }"
      class="font-button mx-auto w-max focus:outline-none text-center my-6 text-blue-900"
    >
      {{ $t("message.member-log-in") }}
    </router-link>
    <p
      class="font-body text-center flex-grow"
      v-html="
        $t('message.terms-policy-cookie-message', {
          terms: termsLink,
          'privacy-policy': privacyPolicyLink
        })
      "
    ></p>
    <NeedHelp />
  </div>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import NeedHelp from "@/components/register/NeedHelp.vue";
import { Auth } from "@aws-amplify/auth";
import { isValidEmailFormat, isValidEmailLength } from "@/utils/basicHelpers";

export default {
  name: "EmailForm",
  components: {
    InputWrapper,
    NeedHelp
  },
  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default() {
        return true;
      }
    }
  },
  data: () => {
    return {
      email: "",
      error: "",
      validation: {},
      showAllErrors: false,
      step: null
    };
  },
  beforeMount() {
    this.step = this.$route.meta.step;
  },
  computed: {
    termsLink() {
      return `<a href='https://www.gameofourlives.org/docs/terms-conditions' target="_blank" class="text-blue-900 underline capitalize">${this.$t(
        "message.terms"
      )}</a>`;
    },
    privacyPolicyLink() {
      return `<a href='https://www.gameofourlives.org/docs/privacy-policy' target="_blank" class="text-blue-900 underline capitalize">${this.$t(
        "message.privacy-policy"
      )}</a>`;
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  mounted() {
    this.email = this.$store.getters["auth/getNewUserEmail"] || "";
  },
  methods: {
    callFederatedSignIn(type) {
      Auth.federatedSignIn({ provider: type });
    },
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      if (this.email == "") {
        this.addError("email", "The email can't be empty");
        valid = false;
      } else if (!isValidEmailLength(this.email)) {
        this.addError("email", "Email is too long");
        valid = false;
      } else if (!isValidEmailFormat(this.email)) {
        this.addError("email", "Invalid email format");
        valid = false;
      } else {
        this.clearError("email");
      }
      return valid;
    },
    next() {
      this.showAllErrors = true;
      const valid = this.validateForm();
      if (valid) {
        this.$store.dispatch("auth/setNewUserEmail", this.email);
        this.$store.dispatch("auth/setNewUserStepCompleted", this.step);
        this.$router.push({ name: "SignUpBirthDateForm" });
      } else {
        this.response = "Please verify the form";
      }
    }
  }
};
</script>
